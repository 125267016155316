import React, { useEffect, useState } from "react"
import { Box, Collapse, Typography, Button } from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import PaperCard from "./paper"
import Title from "./sessiontitle"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    border: "solid 0.1rem #e73d3f99",
    color: "#e73d3f",
    width: `calc(100% - ${theme.spacing(1)}px)`,
    padding: theme.spacing(1.5),
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(10)}px ${theme.spacing(
      0.5
    )}px`,
    "& > span": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > span": {
        fontWeight: "bolder",
      },
    },
  },
  expand: {
    userSelect: "none",
  },
}))

const jump = 15

const Session = ({
  title,
  data,
  showAbs,
  showAut,
  open,
  setOpen,
  forceOpen,
  delay = 0,
  callback
}) => {
  const classes = useStyles()
  const [vis, setVis] = useState(3)
  const [show, setShow] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
      if (callback !== undefined) {
        console.log('done list')
        callback(true)
      }
    }, delay)
  }, [delay, callback])
  title = title.slice(9)

  const handleVis = e => {
    e.target.blur()
    e.stopPropagation()
    document.activeElement.blur()
    if (data?.length > vis) {
      setVis(vis + jump)
    }
  }

  return (
    show && <Box component="section" id={encodeURI(title)}>
      <Title title={title} open={open} toggle={setOpen} len={data.length} />
      <Collapse in={open} mountOnEnter unmountOnExit appear={false}>
        {data
          .slice(0, forceOpen ? data.length : vis)
          .map(({ title, href, authors, abstract }) => {
            return (
              <PaperCard
                key={title}
                title={title}
                href={href}
                authors={authors}
                showAut={showAut}
                abstract={abstract}
                showAbs={showAbs}
              />
            )
          })}
        {!forceOpen && data?.length > vis ? (
          <Button onClick={handleVis} className={classes.root}>
            <Typography
              variant="overline"
              color="inherit"
              className={classes.expand}
            >
              {`Expand ${data.length - vis} More`}
            </Typography>
            <MoreVertIcon color="inherit" />
          </Button>
        ) : (
            <></>
          )}
      </Collapse>
    </Box>
  )
}
export default Session
