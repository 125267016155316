import React from "react"

import { Box, Typography, IconButton, Badge } from "@material-ui/core"

import "./title.css"

import { ExpandMore } from "@material-ui/icons"

import { makeStyles } from "@material-ui/core/styles"

const useStyle = makeStyles(() => ({
  root: {
    transition: "background-color 0.12s",
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
  indicator: {
    color: "red",
    fontSize: "2rem",
  },
  expand: {
    transition: "transform 0.3s",
    transform: "rotate(0deg)",
  },
  close: {
    transition: "transform 0.3s",
    transform: "rotate(180deg)",
  },
}))

const Title = ({ open, toggle, title, len }) => {
  const classes = useStyle()
  if (len) {
    return (
      <Box
        display="flex"
        my={2}
        pl={1}
        borderLeft="solid 0.5em #e73d3f"
        boxSizing="border-size"
        justifyContent="space-between"
        onClick={toggle}
        className={classes.root}
      >
        <Box display="block">
          <Typography variant="caption" component="sub">
            Session
          </Typography>
          <Typography variant="h6" component="h2" color="textPrimary">
            <Box fontWeight="fontWeightBold" fontFamily="Merriweather">
              {title}
            </Box>
          </Typography>
        </Box>
        <Box my="auto" display="block" pr={1}>
          <IconButton aria-label="toggle open">
            <Badge badgeContent={len} showZero max={999} color="secondary">
              <ExpandMore
                className={`${classes.indicator} ${
                  open ? classes.close : classes.expand
                }`}
              />
            </Badge>
          </IconButton>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box pb={1} component="section" id={encodeURI(title)}>
        <Typography variant="caption" component="sub" color="textSecondary">
          No data found in this session
        </Typography>
        <Typography variant="h6" component="h2" color="textSecondary">
          {title}
        </Typography>
      </Box>
    )
  }
}

export default Title
