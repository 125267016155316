import React from "react"
import {
  Typography,
  Card,
  CardContent,
  Chip,
  Avatar,
  Link,
  Tooltip,
  // Box
} from "@material-ui/core"

import "./title.css"
import "./paper.css"

import Abstract from "./abstract"

// import { makeStyles } from '@material-ui/core/styles'
// const useStyles = makeStyles((theme) => ({
//   root: {
//   },
//   title: {
//     fontFamily: 'Merriweather',
//     color: '#e73d3f',
//     display: 'inline-block',
//     boxSizing: 'border-box',
//     borderBottom: 'solid 0.15rem #0000',
//     transition: 'border 0.2s',
//     '&:hover': {
//       borderColor: '#C22',
//       color: '#C22',
//     }
//   },
//   card: {
//     padding: theme.spacing(1),
//     margin: theme.spacing(0.5),
//     marginBottom: theme.spacing(3),
//   },
//   authors: {
//     // boxSizing: 'border-box',
//     marginRight: theme.spacing(0.5),
//     marginBottom: theme.spacing(0.5),
//     '& > span': {
//       userSelect: 'text',
//     }
//   },
// }))

const Author = ({ author }) => {
  const handleSearchAuthor = e => {
    const searchKey = author.replace(" ", "+")
    window.open(
      `https://dl.acm.org/action/doSearch?fillQuickSearch=false&expand=dl&field1=Affiliation&text1=${searchKey}`
    )
  }
  return (
    <Tooltip title={`Search ${author} on ACM DL`}>
      <Chip
        className="paper-author"
        size="small"
        avatar={<Avatar />}
        label={author}
        clickable
        onClick={handleSearchAuthor}
      />
    </Tooltip>
  )
}

const PaperCard = ({ href, title, authors, abstract, showAut, showAbs }) => {
  // const classes = useStyles()
  return (
    <Card variant="outlined" className="paper-block">
      <CardContent>
        <Typography variant="h6" component="h3">
          <Tooltip title="Direct access with ACM DL" placement="right-start">
            <Link href={href} className="paper-title">
              {title}
            </Link>
          </Tooltip>
        </Typography>
        {showAut ? (
          <div className="paper-author-block">
            {authors.map(author => (
              <Author author={author} key={author} />
            ))}
          </div>
        ) : (
          <></>
        )}
      </CardContent>
      {showAbs ? <Abstract data={abstract} title={title} /> : <></>}
    </Card>
  )
}

export default PaperCard
