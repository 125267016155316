import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Switch,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import Session from "./session"
import SettingsIcon from "@material-ui/icons/Settings"

const useStyle = makeStyles(theme => ({
  formRoot: {
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  controlPanel: {
    padding: theme.spacing(1),
  },
}))

const dataFilter = (word, data) => {
  if (word) {
    word = word.toLowerCase()
    return data.filter(({ title }) => title.toLowerCase().includes(word))
  } else {
    return data
  }
}

const List = ({ dataset, word, doneCallback }) => {
  // const theme = useTheme()
  const classes = useStyle()
  const [opens, setOpens] = useState(
    dataset.map((o, idx) => Boolean(idx === 0))
  )
  const [forceOpen, setForceOpen] = useState(false)
  const [showAbs, setShowAbs] = useState(true)
  const [showAut, setShowAut] = useState(true)
  const handleOpener = idx => e => {
    let newOpens = [...opens]
    newOpens[idx] = !newOpens[idx]
    setOpens(newOpens)
  }
  const handleAbs = e => {
    setShowAbs(!showAbs)
  }
  const handleAut = e => {
    setShowAut(!showAut)
  }
  const openAll = e => {
    setOpens(opens.map(() => !forceOpen))
    setForceOpen(!forceOpen)
  }

  return (
    <>
      <Paper variant="outlined" className={classes.controlPanel}>
        <FormGroup classes={{ root: classes.formRoot }}>
          <FormLabel>
            <SettingsIcon />
          </FormLabel>
          <FormControlLabel
            control={<Switch checked={showAbs} onChange={handleAbs} />}
            label="Abstract"
          />
          <FormControlLabel
            control={<Switch checked={showAut} onChange={handleAut} />}
            label="Authors"
          />
          <FormControlLabel
            control={<Switch checked={forceOpen} onChange={openAll} />}
            label="Expand All"
          />
        </FormGroup>
      </Paper>
      {dataset.map(({ session, data }, idx) => {
        return (
          <Session
            title={session}
            data={dataFilter(word, data)}
            key={session}
            open={opens[idx]}
            setOpen={handleOpener(idx)}
            showAbs={showAbs}
            showAut={showAut}
            forceOpen={forceOpen}
            delay={idx * 75}
            callback={idx === (dataset.length - 1) ? doneCallback : undefined}
          />
        )
      })}
    </>
  )
}

export default List
