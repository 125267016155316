import React from "react"
import { Typography, CardContent } from "@material-ui/core"

import "./paper.css"

const Abstract = ({ data, title }) => {
  return (
    <CardContent>
      <Typography variant="caption" color="textSecondary">
        Abstract
      </Typography>
      {data.map((abs, idx) => (
        <Typography
          variant="body1"
          key={`${title}_${idx}`}
          className="abstract"
          children={abs}
        />
      ))}
    </CardContent>
  )
}

export default Abstract
